import React from 'react';
import './Footer.css';

import {ReactComponent as TGLogo} from '../../assets/images/TG Logo.svg';
import {ReactComponent as VKLogo} from '../../assets/images/VK Logo.svg';
import {ReactComponent as EmailLogo} from '../../assets/images/email-svgrepo-com.svg';


function Footer() {
  return (
      <footer>
        <div className="footer-title-container">
          <span className="footer-title">
            Институт&nbsp;№8&nbsp;МАИ<br/>больше&nbsp;чем&nbsp;<span style={{color: '#f534dc'}}>IT.</span>
          </span>
          <span className="footer-title">
            Будьте с нами!
          </span>
        </div>

        <>
          <div className="social">
            <div className="social-item" onClick={() => window.open('https://t.me/pk8mai', '_blank', 'noopener noreferrer')}>
              {/*<img src="/src/assets/images/TG Logo.svg" className="social-icon" alt="telegram_icon"/>*/}
              <TGLogo className="social-icon"/>
              <p>
                ⚡️ Чат абитуриентов 2025:<br/>
                <a href={'https://t.me/pk8mai'} target="_blank"
                   rel="noopener noreferrer">8 институт МАИ - это IT 2025</a>
              </p>
            </div>
            <div className="social-item" onClick={() => window.open('https://vk.com/postupi8', '_blank', 'noopener noreferrer')}>
              <VKLogo className="social-icon"/>
              <p>
                Группа ВК Приемной комиссии:<br/>
                <a href={'https://vk.com/postupi8'} target="_blank"
                   rel="noopener noreferrer">Поступи на #Восьмой</a>
              </p>
            </div>
            <div className="social-item" onClick={() => window.open('https://t.me/itcmai', '_blank', 'noopener noreferrer')}>
              <TGLogo className="social-icon"/>
              <p>
                Телеграм канал IT-центра МАИ:<br/>
                <a href={'https://t.me/itcmai'} target="_blank"
                   rel="noopener noreferrer">IT-Центр МАИ</a>
              </p>
            </div>
            <div className="social-item" onClick={() => window.open('mailto://pk8inst@mai.ru', '_blank', 'noopener noreferrer')}>
              <EmailLogo className="social-icon"/>
              <p>
                Электронная почта Приемной комиссии<br/>
                <a href={'mailto://pk8inst@mai.ru'} target="_blank"
                   rel="noopener noreferrer">pk8inst@mai.ru</a>
              </p>
            </div>
          </div>
          <p>
            Адрес:<br/>
            <a href={'https://yandex.ru/maps/-/CCUDB-tEpD'} target="_blank"
               rel="noopener noreferrer">Москва,
              Волоколамское шоссе 4, корпус 6, 3 этаж, комната 2 (Приемная комиссия)</a>
          </p>
          <p>
            <a href={'https://yandex.ru/maps/-/CCUDB-tEpD'} target="_blank"
               rel="noopener noreferrer">Москва,
              Волоколамское шоссе 4, корпус 6, 6 этаж, комната 607 (Дирекция Института №8)</a>
          </p>
          <p>Номер телефона:<br/>
            <a href={'tel:+74952151789'} target="_blank"
               rel="noopener noreferrer">+7&nbsp;(495)&nbsp;215&nbsp;17&nbsp;89
              (Приемная комиссия)
            </a>
          </p>
        </>

        <div className="legal-info">
          <p>© 2025 Институт №8 "Компьютерные науки и прикладная математика" МАИ (Московский авиационный институт). Все права защищены</p>
          <p>Продолжая использование сайта, вы даете согласие на использование сервисов Яндекс Метрика и Вебвизор.</p>
        </div>

      </footer>
  );
}

export default Footer;