import React, {useEffect, useRef, useState} from 'react';
import FAQSection from "../../components/faqsection/FAQSection";
import axios from "axios";
import {Helmet} from "react-helmet";
import Marquee from "../../components/marquee/Marquee";
import EmblaCarousel from "../../components/Embla Carousel/EmblaCarousel";
import '../../components/highlight/HighLight.css';
import BenefitsList from "../../components/highlight/Highlight";

const HomePage = () => {

  const benefits = [
    {
      title: '🚀 УНИКАЛЬНЫЕ ПРОГРАММЫ',
      description: 'Обучение с лидерами IT: новейшие технологии, преподаватели-практики, проектная работа, междисциплинарные модули и проекты. Станьте лучшими в своей области!'
    },
    {
      title: '🎯 АКТУАЛЬНОЕ ОБУЧЕНИЕ',
      description: 'Обучение в тесной связи с индустрией, подготовка к актуальным требованиям работодателей. Практические задачи и реальные проекты делают вас профессионалами, готовыми к вызовам современности.'
    },
    {
      title: '💻 IT-МЕРОПРИЯТИЯ',
      description: 'Современные методики обучения, дополнительные образовательные программы и мастер-классы от лучших специалистов IT и бизнеса, хакатоны, акселерационные программы и поддержка студенческих стартапов.'
    },
    {
      title: '🔗 АССОЦИИРОВАННЫЕ ПРОГРАММЫ ЯНДЕКСА',
      description: 'Студенты 1-2 курсов изучают программирование на Python, C++ и алгоритмы. Учащиеся 3-4 курсов проходят специализированные курсы по Backend и Frontend-разработке.'
    },
    {
      title: '🌍 СТРАТЕГИЧЕСКИЕ ПАРТНЕРСТВА',
      description: 'Сотрудничество с ведущими компаниями IT и бизнес-инкубаторами для создания уникальных образовательных программ и доступа к актуальным проектам.'
    },
    {
      title: '🌟 ПОДДЕРЖКА',
      description: 'Присоединяйтесь к комьюнити выпускников и партнеров: наставничество, менторство, нетворкинг и активное участие в жизни университета. Вместе мы достигаем больших результатов!'
    },
  ];


  const highlightsBachelor = [
    {
      title: '01.03.00 Компьютерные науки и прикладная математика',
      description: '<p class="p_description"><span class="d_description">Кол-во бюджетных мест: </span><span class="v_description">225</span></p>' +
          '<p class="p_description"><span class="d_description">Кол-во платных мест: </span><span class="v_description">20+</span></p>' +
          '<p class="p_description"><span class="d_description">Проходной балл: </span><span class="v_description">253</span></p>' +
          '<p class="p_description"><span class="d_description">Экзамены: </span><span class="dv_description">Русский язык, Математика, Информатика или Физика</span></p>' +
          '<p class="p_description"><span class="d_description">Форма обучения: </span><span class="dv_description">Очная</span></p>',
      link: 'https://priem.mai.ru/bachelor/programs/?mega=%D0%9A%D0%BE%D0%BC%D0%BF%D1%8C%D1%8E%D1%82%D0%B5%D1%80%D0%BD%D1%8B%D0%B5+%D0%BD%D0%B0%D1%83%D0%BA%D0%B8+%D0%B8+%D0%BF%D1%80%D0%B8%D0%BA%D0%BB%D0%B0%D0%B4%D0%BD%D0%B0%D1%8F+%D0%BC%D0%B0%D1%82%D0%B5%D0%BC%D0%B0%D1%82%D0%B8%D0%BA%D0%B0&city=%D0%9C%D0%BE%D1%81%D0%BA%D0%B2%D0%B0#:~:text=%D0%BE%D0%B1%D1%80%D0%B0%D0%B7%D0%BE%D0%B2%D0%B0%D0%BD%D0%B8%D0%B5%20(4%20%D0%B3%D0%BE%D0%B4%D0%B0)-,%D0%9A%D0%BE%D0%BC%D0%BF%D1%8C%D1%8E%D1%82%D0%B5%D1%80%D0%BD%D1%8B%D0%B5%20%D0%BD%D0%B0%D1%83%D0%BA%D0%B8%20%D0%B8%20%D0%BF%D1%80%D0%B8%D0%BA%D0%BB%D0%B0%D0%B4%D0%BD%D0%B0%D1%8F%20%D0%BC%D0%B0%D1%82%D0%B5%D0%BC%D0%B0%D1%82%D0%B8%D0%BA%D0%B0,-01.03.00',
    },
    {
      title: '02.03.02 Фундаментальная информатика и информационные технологии',
      description: '<p class="p_description"><span class="d_description">Кол-во бюджетных мест: </span><span class="v_description">125</span></p>' +
          '<p class="p_description"><span class="d_description">Кол-во платных мест: </span><span class="v_description">20+</span></p>' +
          '<p class="p_description"><span class="d_description">Проходной балл: </span><span class="v_description">257</span></p>' +
          '<p class="p_description"><span class="d_description">Экзамены: </span><span class="dv_description">Русский язык, Математика, Информатика или Физика</span></p>' +
          '<p class="p_description"><span class="d_description">Форма обучения: </span><span class="dv_description">Очная</span></p>',
      link: 'https://priem.mai.ru/bachelor/programs/?mega=%D0%9A%D0%BE%D0%BC%D0%BF%D1%8C%D1%8E%D1%82%D0%B5%D1%80%D0%BD%D1%8B%D0%B5+%D0%BD%D0%B0%D1%83%D0%BA%D0%B8+%D0%B8+%D0%BF%D1%80%D0%B8%D0%BA%D0%BB%D0%B0%D0%B4%D0%BD%D0%B0%D1%8F+%D0%BC%D0%B0%D1%82%D0%B5%D0%BC%D0%B0%D1%82%D0%B8%D0%BA%D0%B0&city=%D0%9C%D0%BE%D1%81%D0%BA%D0%B2%D0%B0#:~:text=%D0%A4%D1%83%D0%BD%D0%B4%D0%B0%D0%BC%D0%B5%D0%BD%D1%82%D0%B0%D0%BB%D1%8C%D0%BD%D0%B0%D1%8F%20%D0%B8%D0%BD%D1%84%D0%BE%D1%80%D0%BC%D0%B0%D1%82%D0%B8%D0%BA%D0%B0%20%D0%B8%20%D0%B8%D0%BD%D1%84%D0%BE%D1%80%D0%BC%D0%B0%D1%86%D0%B8%D0%BE%D0%BD%D0%BD%D1%8B%D0%B5%20%D1%82%D0%B5%D1%85%D0%BD%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D0%B8',
    }
  ];

  const highlightsMaster = [
    {
      title: '01.04.02 Прикладная математика и информатика',
      description: '<p class="p_description"><span class="d_description">Кол-во бюджетных мест: </span><span class="v_description">40</span></p>' +
          '<p class="p_description"><span class="d_description">Кол-во платных мест: </span><span class="v_description">10+</span></p>' +
          '<p class="p_description"><span class="d_description">Форма обучения: </span><span class="dv_description">Очная</span></p>',
      link: 'https://priem.mai.ru/master/programs/?mega=&mega=Компьютерные+науки+и+прикладная+математика',
    },
    {
      title: '01.04.04 Прикладная математика',
      description: '<p class="p_description"><span class="d_description">Кол-во бюджетных мест: </span><span class="v_description">50</span></p>' +
          '<p class="p_description"><span class="d_description">Кол-во платных мест: </span><span class="v_description">10+</span></p>' +
          '<p class="p_description"><span class="d_description">Форма обучения: </span><span class="dv_description">Очная</span></p>',
      link: 'https://priem.mai.ru/master/programs/?mega=&mega=Компьютерные+науки+и+прикладная+математика',
    },
    {
      title: '02.04.02 Фундаментальная информатика и информационные технологии',
      description: '<p class="p_description"><span class="d_description">Кол-во бюджетных мест: </span><span class="v_description">65</span></p>' +
          '<p class="p_description"><span class="d_description">Кол-во платных мест: </span><span class="v_description">10+</span></p>' +
          '<p class="p_description"><span class="d_description">Форма обучения:</span><span class="dv_description">Очная</span></p>',
      link: 'https://priem.mai.ru/master/programs/?mega=&mega=Компьютерные+науки+и+прикладная+математика',
    },
    {
      title: '<span class="sber-title">Онлайн-магистратура «Машинное обучение и анализ данных»</span>',
      description: '<p class="p_description"><span class="d_description">Онлайн-магистратура от МАИ и Сбера "Машинное обучение и анализ данных".</span></p>' +
          '<p class="p_description"><span class="d_description">Кол-во платных мест: </span><span class="v_description">40+</span></p>' +
          '<p class="p_description"><span class="d_description">Форма обучения: </span><span class="dv_description">Очная (Онлайн)</span></p>',
      link: 'https://priem.mai.ru/spec/programs/mashinnoe-obuchenie-i-analiz-dannykh/',
    },
    {
      title: '<span class="t1-title">«Большие данные и машинное обучение»</span>',
      description: '<p class="p_description"><span class="d_description">Одногодичная программа от МАИ и Т1.</span></p>' +
          '<p class="p_description"><span class="d_description">Кол-во платных мест: </span><span class="v_description">15+</span></p>' +
          '<p class="p_description"><span class="d_description">Форма обучения: </span><span class="dv_description">Очная (Онлайн)</span></p>',
      link: 'https://priem.mai.ru/spec/programs/mashinnoe-obuchenie-i-analiz-dannykh/',
    },
  ];

  const faqs = [{
    question: '8 > 3', answer: 'True',
  }, {
    question: 'Кем становятся выпускники после обучения на программах Института №8?',
    answer: 'Студенты и выпускники работают в различных сферах. Самые частые профессии:<ul>\n' + '  <li>\n' + '    <p>Программисты и разработчики: веб, мобильные приложения, программное обеспечение, игры;</p>\n' + '  </li>\n' + '  <li>\n' + '    <p>Системные аналитики: анализ бизнес-потребностей, оптимизация рабочих процессов;</p>\n' + '  </li>\n' + '  <li>\n' + '    <p>Инженеры по тестированию ПО: тестирование, обеспечение качества;</p>\n' + '  </li>\n' + '  <li>\n' + '    <p>Сетевые и системные администраторы: управление сетями, безопасность;</p>\n' + '  </li>\n' + '  <li>\n' + '    <p>Аналитики данных: анализ больших данных, создание инсайтов;</p>\n' + '  </li>\n' + '  <li>\n' + '    <p>Инженеры и архитекторы искусственного интеллекта: разработка решений ИИ, машинное обучение;</p>\n' + '  </li>\n' + '  <li>\n' + '    <p>Консультанты по IT: оптимизация технических процессов, бизнес-потоки;</p>\n' + '  </li>\n' + '  <li>\n' + '    <p>Преподаватели и исследователи: наука, университеты, исследовательские лаборатории;</p>\n' + '  </li>\n' + '  <li>\n' + '    <p>Криптографы и специалисты по кибербезопасности: защита информации, криптографические системы.</p>\n' + '  </li>\n' + '</ul>\n',
  }, {
    question: 'Как получить общежитие?',
    answer: 'Общежитие предоставляется иногородним студентам, не проживающим в Москве или ближайшем Подмосковье, на конкурсной основе. Для конкурсной группы 01.03.00 "Компьютерные науки и прикладная математика" выделено 104 места, а для группы 02.03.02 "Фундаментальная информатика и информационные технологии" - 57 мест.',
  }, {
    question: 'Есть ли военная кафедра?',
    answer: 'Да, в нашем университете есть возможность прохождения обучения на военной кафедре. Для этого необходимо подать заявление на участие в конкурсе на втором семестре обучения. Прохождение на военную кафедру предполагает сдачу нормативов по физической подготовке (бег на 3 км, бег на 100 м, подтягивания) и прохождение медицинского осмотра в военкомате. Обучение на военной кафедре начинается с второго курса и проходит один раз в неделю. Продолжительность обучения составляет 3 года. По окончании обучения студентам присваивается воинское звание \'Лейтенант запаса\'.',
  }, {
    question: 'Как можно подать документы на поступление?',
    answer: 'Документы в ВУЗ можно подать любым из следующих способов:' + '<ul>\n' + '  <li>\n' + '    <p>Лично, в приёмной комиссии в вузе</p>\n' + '  </li>\n' + '  </li>\n' + '  <li>\n' + '    <p>Суперсервис "Поступление в вуз онлайн"</p>\n' + '  </li>\n' + '  <li>\n' + '    <p>Через операторов почтовой связи</p>\n' + '  </li>\n' + '</ul>'
    // + 'Узнать подробнее про поступление и подать документы можно <a href="/admission/application" style="color: #007acd" target="_blank" rel="noopener noreferrer">тут</a>.',
  }, {
    question: 'Какие есть дополнительные возможности для развития?',
    answer: 'Институт №8 МАИ предлагает студентам разнообразные возможности развития: Авиахакатон с задачами от крупных компаний, Весенняя школа математического моделирования и IT с лекциями от специалистов, а также дни IT компаний с лекциями и информацией о стажировках.<br/>Все это способствует развитию навыков, погружению в современные технологии и установлению связей с ведущими компаниями.'
  }];

  const [logoData, setLogoData] = useState(null);
  const [carouselItems, setCarouselItems] = useState(null);
  const [companyLogoData, setCompanyLogoData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const eventResponse = await axios.get('https://itmai.ru/storage/event2.json');
        // setPopupData(eventResponse.data);

        const logoResponse = await axios.get('https://itmai.ru/storage/stacklogos.json');
        setLogoData(logoResponse.data);

        const carouselResponse = await axios.get('https://itmai.ru/storage/carousel.json');
        setCarouselItems(carouselResponse.data);

        const companyLogoResponse = await axios.get('https://itmai.ru/storage/komplogos.json');
        setCompanyLogoData(companyLogoResponse.data);
      } catch (error) {

      }
    };

    fetchData();
  }, []);

  const description = "Институт №8 «Компьютерные науки и прикладная математика» — признанная в России и за рубежом научная математическая и IT-школа. Уже более полувека мы готовим талантливых профессионалов, которые успешно применяют свои знания в различных областях, включая IT, финансы, робототехнику, экономику, авиацию, космонавтику и другие востребованные направления.";
  const keywords = "Институт №8, Компьютерные науки, Прикладная математика, IT-школа, профессионалы, образование, IT, финансы, робототехника, экономика, авиация, космонавтика, научные исследования, преподаватели, высокотехнологичные компании, студенты, научные мероприятия, карьера";

  const OPTIONS = {loop: true}
  var SLIDES = [];
  for (let i = 0; i < 11; i++) {
    SLIDES.push('../../assets/images/slider/' + i + '.jpg')
  }

  const [isOpen, setIsOpen] = useState(false);
  const [isWelcomePopupOpen, setIsWelcomePopupOpen] = useState(true);

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleWelcomePopupClose = () => {
    setIsWelcomePopupOpen(false);
  };

  const [iframeHeight, setIframeHeight] = useState('650px'); // Установите начальную высоту iframe
  const iframeRef = useRef(null);

  useEffect(() => {
    const resizeIframe = () => {
      if (iframeRef.current) {
        setIframeHeight(`${iframeRef.current.contentWindow.document.body.scrollHeight}px`);
      }
    };

    if (iframeRef.current) {
      iframeRef.current.addEventListener('load', resizeIframe);
      resizeIframe(); // Вызовите функцию один раз после монтирования компонента, чтобы установить правильную высоту сразу
    }

    return () => {
      if (iframeRef.current) {
        iframeRef.current.removeEventListener('load', resizeIframe);
      }
    };
  }, []);

  return (
      <div className='container'>
        <Helmet>
          <meta name="description" content={description}/>
          <meta name="keywords" content={keywords}/>
        </Helmet>

        {/*<Popup isOpen={isOpen} onClose={handleClose} maxWidth="600px">*/}
        {/*  <h2 style={{fontSize: '2rem', color: '#90d3f9'}}>*/}
        {/*    Регистрация на день открытых дверей*/}
        {/*  </h2>*/}
        {/*  <div style={{display: "flex", justifyContent: "center"}}>*/}
        {/*    <div>*/}
        {/*      <iframe ref={iframeRef} src="https://forms.yandex.ru/cloud/65ec9baf068ff06a6f6baa8c/?iframe=1"*/}
        {/*              frameBorder="0"*/}
        {/*              name="ya-form-65ec9baf068ff06a6f6baa8c"*/}
        {/*              style={{width: '100%', height: iframeHeight, maxHeight: '600px', overflow: "hidden"}}*/}
        {/*      ></iframe>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</Popup>*/}

        {/*<Popup isOpen={isWelcomePopupOpen} onClose={handleWelcomePopupClose} maxWidth="600px">*/}
        {/*  <h2 style={{fontSize: '2rem', color: '#90d3f9'}}>*/}
        {/*    Привет, абитуриент!*/}
        {/*  </h2>*/}
        {/*  <div style={{}}>*/}
        {/*    <p style={{fontSize: '1.3rem', lineHeight: 1.3, fontWeight: 400}}>*/}
        {/*      Присоединяйся к нашему телеграм-чату для абитуриентов 2024 года! В нем ты сможешь получать оперативную*/}
        {/*      информацию от приемной комиссии и задавать любые вопросы директору Института №8, Сергею Сергеевичу*/}
        {/*      Крылову.*/}
        {/*      <br/>*/}
        {/*      <div style={{marginTop: '1rem'}}>*/}
        {/*        <a href='https://t.me/pk8mai' target='_blank' rel='noopener noreferrer'*/}
        {/*           style={{color: '#90d3f9', textDecoration: 'none'}}>*/}
        {/*          <div style={{display: 'flex', alignItems: 'center', justifyContent: 'start', fontSize: '1.5rem'}}>*/}
        {/*            Присоединиться к чату <ArrowRight style={{marginLeft: '5px'}} size={30}/>*/}
        {/*          </div>*/}
        {/*        </a>*/}
        {/*      </div>*/}
        {/*    </p>*/}
        {/*  </div>*/}
        {/*</Popup>*/}

        <div className='scroll-container'>
          <div className="scroll-item">
            <div className='cool-header'
                 style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh'}}>
              <span className="footer-title" style={{textAlign: 'center', fontSize: '1.5rem'}}>
                Институт&nbsp;№8&nbsp;МАИ<br/>больше&nbsp;чем&nbsp;<span style={{color: '#f534dc'}}>IT.</span>
              </span>
            </div>
          </div>
          <div className="scroll-item">
            <section id="about-institute">
              {/*<AnimatedNumberComponent id={1} value={163} duration={3000} description={'преподавателя'}/>*/}
              {/*<AnimatedNumberComponent id={2} value={20} duration={3000} description={'компаний-партнеров'}/>*/}
              {/*<AnimatedNumberComponent id={3} value={6} duration={3000}*/}
              {/*                         description={'место по популярности по версии Табитуриент'}/>*/}
              {/*<AnimatedNumberComponent id={4} value={1} duration={3000} description={'робособака Дора'}/>*/}
              {/*<AnimatedNumberComponent id={5} value={1024} duration={3000}*/}
              {/*                         description={'квадратных метра IT-пространства'}/>*/}

              <p className="section-text">
                Добро пожаловать в Институт №8 "Компьютерные науки и прикладная математика"! Мы гордимся тем, что
                являемся
                ведущим научным, математическим и IT-центром, признанным не только в России, но и на международной
                арене.
                Наши студенты и выпускники применяют полученные знания в самых передовых и востребованных сферах: IT,
                финансы, робототехника, экономика, авиация и космонавтика.
              </p>
              <p className="section-text">
                Уникальность нашего института заключается в тесном взаимодействии с лидерами IT-индустрии и научными
                организациями. Этот подход позволяет нашим студентам развивать исследовательские навыки, углублять
                понимание предмета и активно делиться знаниями с коллегами. Уже в процессе обучения студенты получают
                уникальный опыт работы в крупнейших компаниях, таких как Сбер, Т-Банк (Тинькофф), МТС, Яндекс, VK,
                Лаборатория
                Касперского и другие лидеры рынка. Этот опыт не только обогащает образование, но и создает прочную
                основу
                для успешной карьеры после окончания обучения.
              </p>
              <p className="section-text">
                Поступая к нам, вы открываете двери к безграничным возможностям и перспективам. Наши выпускники
                востребованы на рынке труда и занимают ключевые позиции в ведущих компаниях. Присоединяйтесь к нам и
                станьте частью сообщества, которое формирует будущее IT и науки.
              </p>

              <div style={{paddingTop: '2rem', paddingBottom: '2rem'}} className="marquee_wrapper">
                <div className="marquee_items">
                  <div className="marquee_item">дни it-компаний</div>
                  <div className="marquee_item">робособака Дора</div>
                  <div className="marquee_item">380 терафлопс супервычислителя</div>
                  <div className="marquee_item">it-пространство</div>
                  <div className="marquee_item">авиахакатон</div>
                  <div className="marquee_item">школа матмода</div>
                  <div className="marquee_item">it-проекты</div>
                  <div className="marquee_item">технологичные лаборатории</div>
                  <div className="marquee_item">преподаватели-практики</div>
                  <div className="marquee_item">практический опыт</div>
                  <div className="marquee_item">современные технологии</div>

                  <div className="marquee_item">дни it-компаний</div>
                  <div className="marquee_item">робособака Дора</div>
                  <div className="marquee_item">380 терафлопс супервычислителя</div>
                  <div className="marquee_item">it-пространство</div>
                  <div className="marquee_item">авиахакатон</div>
                  <div className="marquee_item">школа матмода</div>
                  <div className="marquee_item">it-проекты</div>
                  <div className="marquee_item">технологичные лаборатории</div>
                  <div className="marquee_item">преподаватели-практики</div>
                  <div className="marquee_item">практический опыт</div>
                  <div className="marquee_item">современные технологии</div>
                </div>
              </div>

              {carouselItems ? (<EmblaCarousel slides={carouselItems.images} options={OPTIONS}/>) : (
                  <p>Загрузка...</p>)}

            </section>

            {/*<div className='block-dod'>*/}
            {/*  <div className="marquee_wrapper" style={{paddingTop: '1rem'}}>*/}
            {/*    <div className="marquee_items">*/}
            {/*      <div className="">*/}
            {/*      <span className='block-dod-slogan'>если все мысли об <span*/}
            {/*          className='block-dod-slogan-accent'>IT</span> <ArrowSvg className='ar'/> поступай в <span*/}
            {/*          className='block-dod-slogan-accent'>МАИ</span>*/}
            {/*      </span>*/}
            {/*      </div>*/}
            {/*      <div className="">*/}
            {/*      <span className='block-dod-slogan'>если все мысли об <span*/}
            {/*          className='block-dod-slogan-accent'>IT</span> <ArrowSvg className='ar'/> поступай в <span*/}
            {/*          className='block-dod-slogan-accent'>МАИ</span>*/}
            {/*      </span>*/}
            {/*      </div>*/}
            {/*      <div className="">*/}
            {/*      <span className='block-dod-slogan'>если все мысли об <span*/}
            {/*          className='block-dod-slogan-accent'>IT</span> <ArrowSvg className='ar'/> поступай в <span*/}
            {/*          className='block-dod-slogan-accent'>МАИ</span>*/}
            {/*      </span>*/}
            {/*      </div>*/}
            {/*      <div className="">*/}
            {/*      <span className='block-dod-slogan'>если все мысли об <span*/}
            {/*          className='block-dod-slogan-accent'>IT</span> <ArrowSvg className='ar'/> поступай в <span*/}
            {/*          className='block-dod-slogan-accent'>МАИ</span>*/}
            {/*      </span>*/}
            {/*      </div>*/}
            {/*      <div className="">*/}
            {/*      <span className='block-dod-slogan'>если все мысли об <span*/}
            {/*          className='block-dod-slogan-accent'>IT</span> <ArrowSvg className='ar'/> поступай в <span*/}
            {/*          className='block-dod-slogan-accent'>МАИ</span>*/}
            {/*      </span>*/}
            {/*      </div>*/}
            {/*      <div className="">*/}
            {/*      <span className='block-dod-slogan'>если все мысли об <span*/}
            {/*          className='block-dod-slogan-accent'>IT</span> <ArrowSvg className='ar'/> поступай в <span*/}
            {/*          className='block-dod-slogan-accent'>МАИ</span>*/}
            {/*      </span>*/}
            {/*      </div>*/}
            {/*      <div className="">*/}
            {/*      <span className='block-dod-slogan'>если все мысли об <span*/}
            {/*          className='block-dod-slogan-accent'>IT</span> <ArrowSvg className='ar'/> поступай в <span*/}
            {/*          className='block-dod-slogan-accent'>МАИ</span>*/}
            {/*      </span>*/}
            {/*      </div>*/}
            {/*      <div className="">*/}
            {/*      <span className='block-dod-slogan'>если все мысли об <span*/}
            {/*          className='block-dod-slogan-accent'>IT</span> <ArrowSvg className='ar'/> поступай в <span*/}
            {/*          className='block-dod-slogan-accent'>МАИ</span>*/}
            {/*      </span>*/}
            {/*      </div>*/}
            {/*      <div className="">*/}
            {/*      <span className='block-dod-slogan'>если все мысли об <span*/}
            {/*          className='block-dod-slogan-accent'>IT</span> <ArrowSvg className='ar'/> поступай в <span*/}
            {/*          className='block-dod-slogan-accent'>МАИ</span>*/}
            {/*      </span>*/}
            {/*      </div>*/}
            {/*      <div className="">*/}
            {/*      <span className='block-dod-slogan'>если все мысли об <span*/}
            {/*          className='block-dod-slogan-accent'>IT</span> <ArrowSvg className='ar'/> поступай в <span*/}
            {/*          className='block-dod-slogan-accent'>МАИ</span>*/}
            {/*      </span>*/}
            {/*      </div>*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*  <div className='block-dod-main' style={{*/}
            {/*    paddingLeft: '50px',*/}
            {/*    paddingBottom: '50px',*/}
            {/*    paddingTop: '25px',*/}
            {/*    display: 'flex',*/}
            {/*    justifyContent: 'space-between',*/}
            {/*    flexDirection: 'column',*/}
            {/*    flex: 1*/}
            {/*  }}>*/}
            {/*    <div className='block-dod-date'>27 апреля 2024</div>*/}
            {/*    <div style={{*/}
            {/*      display: 'flex',*/}
            {/*      flexDirection: 'row',*/}
            {/*      gap: '20px',*/}
            {/*      marginTop: '10px',*/}
            {/*      marginBottom: '10px',*/}
            {/*    }}>*/}
            {/*      <div className='block-dod-logo--main'>16:00 - 17:00</div>*/}
            {/*      <div className='block-dod-logo--main'>онлайн</div>*/}
            {/*    </div>*/}
            {/*    <div className='block-dod-name'>День открытых дверей</div>*/}
            {/*    <div className='block-dod-inst'>Институт №8 "Компьютерные науки и прикладная математика"</div>*/}
            {/*    <button className='block-dod-logo button' onClick={() => {*/}
            {/*      setIsOpen(true);*/}
            {/*    }}>Зарегистрироваться*/}
            {/*    </button>*/}
            {/*  </div>*/}
            {/*</div>*/}

            <div className="content">
              {/*<div className="highlights-container">*/}
              {/*  <div*/}
              {/*      style={{width: '550px'}}*/}
              {/*      className="highlight"*/}
              {/*      onClick={() => window.location.href = '/priem'}*/}
              {/*  >*/}
              {/*    <div className="button_sec">*/}
              {/*      Приемная комиссия*/}
              {/*    </div>*/}
              {/*  </div>*/}

              {/*  <div*/}
              {/*      style={{width: '550px'}}*/}
              {/*      className="highlight"*/}
              {/*      onClick={() => {*/}
              {/*        const contactsElement = document.getElementById('contacts');*/}
              {/*        contactsElement.scrollIntoView({behavior: 'smooth'});*/}
              {/*      }}*/}
              {/*  >*/}
              {/*    <div className="button_sec">*/}
              {/*      Связаться с нами*/}
              {/*    </div>*/}
              {/*  </div>*/}
              {/*</div>*/}
              <section id='institute-specialization'>
                <h2>Направления подготовки</h2>
                <section id='about-bvo-spvo'>
                  <p className="section-text">
                    В России запускается новая модель высшего образования, и МАИ на передовой! Теперь обучение — это три
                    уровня, гибкая система и участие топовых компаний. Готовим специалистов, которые легко адаптируются
                    к
                    современным вызовам и технологическим трендам. Стройте карьеру будущего с МАИ и будьте частью
                    инновационного поколения!
                  </p>
                </section>

                <section id='bvo-spec'>
                  <h3>Базовое высшее образование (4 года)</h3>
                  <p className="section-text">
                    В МАИ стартовал пилотный проект по трансформации высшего образования, вводя базовое высшее
                    образование для подготовки специалистов с фундаментальными знаниями и бизнес и IT-компетенциями.
                    Сотрудничество с ведущими корпорациями гарантирует актуальные программы, отражающие реальные
                    потребности индустрии и современные технологические тренды, делая выпускников востребованными и
                    готовыми к вызовам будущего.
                  </p>
                  <div className="section-text">
                    <div className="program-group-name">01.03.02 "Прикладная математика и информатика"</div>

                    <div className="program-group-feature">
                      <div className="feature">
                        <span className="feature-number">261 / 190</span>
                        <span className="feature-description">проходные баллы 2024 г.</span>
                        <span className="feature-description">(бюджет / платное)</span>
                      </div>
                      <div className="feature">
                        <span className="feature-number">145</span>
                        <span className="feature-description">бюджетных мест</span>
                      </div>
                      <div className="feature">
                        <span className="feature-number">100</span>
                        <span className="feature-description">платных мест</span>
                      </div>
                      <div className="feature">
                        <span className="feature-number">Инженер</span>
                        <span className="feature-description">квалификация</span>
                      </div>
                    </div>

                    <div>Образовательные программы:</div>
                    <div className="programs">
                      <div className="program">
                        <span className="program-name">Информатика</span>
                        <span className="department">806 каф.</span>
                      </div>
                    </div>
                  </div>

                  <div className="section-text">
                    <div className="program-group-name">01.03.04 "Прикладная математика"</div>

                    <div className="program-group-feature">
                      <div className="feature">
                        <span className="feature-number">261 / 190</span>
                        <span className="feature-description">проходные баллы 2024 г.</span>
                        <span className="feature-description">(бюджет / платное)</span>
                      </div>
                      <div className="feature">
                        <span className="feature-number">80</span>
                        <span className="feature-description">бюджетных мест</span>
                      </div>
                      <div className="feature">
                        <span className="feature-number">20</span>
                        <span className="feature-description">платных мест</span>
                      </div>
                      <div className="feature">
                        <span className="feature-number">Инженер</span>
                        <span className="feature-description">квалификация</span>
                      </div>
                    </div>

                    <div>Образовательные программы:</div>
                    <div className="programs">
                      <div className="program">
                        <span className="program-name">Математическое и программное обеспечение систем обработки информации и управления</span>
                        <span className="department">805 каф.</span>
                      </div>
                      <div className="program">
                        <span className="program-name">Статистический анализ и оптимизация сложных технических и экономических систем</span>
                        <span className="department">804 каф.</span>
                      </div>
                      <div className="program">
                        <span className="program-name">Математическое и компьютерное моделирование в механике</span>
                        <span className="department">802 каф.</span>
                      </div>
                    </div>
                  </div>

                  <div className="section-text">
                    <div className="program-group-name">02.03.02 "Фундаментальная информатика и информационные
                      технологии"
                    </div>

                    <div className="program-group-feature">
                      <div className="feature">
                        <span className="feature-number">265 / 188</span>
                        <span className="feature-description">проходные баллы 2024 г.</span>
                        <span className="feature-description">(бюджет / платное)</span>
                      </div>
                      <div className="feature">
                        <span className="feature-number">125</span>
                        <span className="feature-description">бюджетных мест</span>
                      </div>
                      <div className="feature">
                        <span className="feature-number">50</span>
                        <span className="feature-description">платных мест</span>
                      </div>
                      <div className="feature">
                        <span className="feature-number">Инженер</span>
                        <span className="feature-description">квалификация</span>
                      </div>
                    </div>

                    <div>Образовательная программа:</div>
                    <div className="programs">
                      <div className="program">
                        <span className="program-name">Компьютерные науки</span>
                        <span className="department">806 каф.</span>
                      </div>
                    </div>
                  </div>

                  {/*<Highlight data={highlightsBachelor} highlightWidth="550px"/>*/}
                </section>
                <section id='spvo-spec'>
                  <h3>Специализированное высшее образование (IT-магистратура)</h3>
                  <p className="section-text">
                    С введением специализированного высшего образования, МАИ выводит подготовку профессионалов на новый
                    уровень. Программы продолжительностью 1-2 года углубляют знания и навыки, формируются с участием
                    топовых партнеров, обеспечивая их актуальность и практическую направленность. Студенты работают над
                    проектами, развивают soft skills и учатся управлению проектами, становясь высококвалифицированными и
                    востребованными специалистами.
                  </p>
                  <div className="section-text">
                    <div className="program-group-name">01.04.02 "Прикладная математика и информатика"
                    </div>

                    <div className="program-group-feature">
                      <div className="feature">
                        <span className="feature-number">40</span>
                        <span className="feature-description">бюджетных мест</span>
                      </div>
                      <div className="feature">
                        <span className="feature-number">10</span>
                        <span className="feature-description">платных мест</span>
                      </div>
                      <div className="feature">
                        <span className="feature-number">Инженер-исследователь</span>
                        <span className="feature-description">квалификация</span>
                      </div>
                    </div>

                    <div>Образовательная программа:</div>
                    <div className="programs">
                      <div className="program">
                        <span className="program-name">Продуктовая разработка и разработка программных систем</span>
                        {/*<span className="program-code">02.03.02</span>*/}
                        <span className="department">806 каф.</span>
                      </div>
                    </div>
                  </div>
                  <div className="section-text">
                    <div className="program-group-name">01.04.04 "Прикладная математика"
                    </div>

                    <div className="program-group-feature">
                      <div className="feature">
                        <span className="feature-number">50</span>
                        <span className="feature-description">бюджетных мест</span>
                      </div>
                      <div className="feature">
                        <span className="feature-number">10</span>
                        <span className="feature-description">платных мест</span>
                      </div>
                      <div className="feature">
                        <span className="feature-number">Инженер-исследователь</span>
                        <span className="feature-description">квалификация</span>
                      </div>
                    </div>

                    <div>Образовательные программы:</div>
                    <div className="programs">
                      <div className="program">
                        <span
                            className="program-name">Математическое и программное обеспечение мехатронных систем</span>
                        <span className="department">802 каф.</span>
                      </div>
                      <div className="program">
                        <span
                            className="program-name">Компьютерное моделирование информационных и экономических систем</span>
                        <span className="department">804 каф.</span>
                      </div>
                      <div className="program">
                        <span
                            className="program-name">Информационные технологии в управлении</span>
                        <span className="department">805 каф.</span>
                      </div>
                    </div>
                  </div>
                  <div className="section-text">
                    <div className="program-group-name">02.04.02 "Фундаментальная информатика и информационные
                      технологии"
                    </div>

                    <div className="program-group-feature">
                      <div className="feature">
                        <span className="feature-number">65</span>
                        <span className="feature-description">бюджетных мест</span>
                      </div>
                      <div className="feature">
                        <span className="feature-number">60</span>
                        <span className="feature-description">платных мест</span>
                      </div>
                      <div className="feature">
                        <span className="feature-number">Инженер-исследователь</span>
                        <span className="feature-description">квалификация</span>
                      </div>
                    </div>

                    <div>Образовательные программы:</div>
                    <div className="programs">
                      <div className="program">
                        <span
                            className="program-name">Искусственный интеллект и суперкомпьютерные технологии</span>
                        <span className="department">806 каф.</span>
                      </div>
                      <div className="program">
                        <span
                            className="program-name">Машинное обучение и анализ данных (совместная онлайн-программа с Сбер Edutoria )</span>
                        <span className="department">806 каф.</span>
                      </div>
                      <div className="program">
                        <span
                            className="program-name">Большие данные и машинное обучение (совместная программа с Т1, срок обучения 1 год)</span>
                        <span className="department">806 каф.</span>
                      </div>
                    </div>
                  </div>

                  {/*<Highlight data={highlightsMaster} highlightWidth="550px"/>*/}
                </section>
              </section>
              <section id='technology'>
                <h2>Облако компетенций выпускника</h2>
                {logoData ? (<Marquee logos={logoData.stacklogos}/>) : (<p>Загрузка...</p>)}
              </section>

              <section id='technology'>
                <h2>Партнеры</h2>
                {companyLogoData ? (<Marquee logos={companyLogoData.komplogos}/>) : (<p>Загрузка...</p>)}
              </section>

              <section id='benifits'>
                <h2>Преимущества обучения</h2>
                <BenefitsList benefits={benefits}/>
              </section>
              <section id='faqs'>
                <h2>Частые вопросы</h2>
                <FAQSection faqs={faqs}/>
              </section>
            </div>
          </div>
        </div>
      </div>
  )
      ;
};

export default HomePage;