import React from 'react';
import './HighLight.css'; // Подключаем файл стилей

const BenefitItem = ({ title, description }) => {
  return (
      <div className="benefit-item">
        <div className="benefit-title">{title}</div>
        <div className="benefit-description">{description}</div>
      </div>
  );
};

const BenefitsList = ({ benefits }) => {
  return (
      <div className="benefits-list">
        {benefits.map((benefit, index) => (
            <BenefitItem key={index} title={benefit.title} description={benefit.description} />
        ))}
      </div>
  );
};

export default BenefitsList;